import {Overlay, OverlayConfig} from '@angular/cdk/overlay';
import {Injectable} from '@angular/core';
import {OverlayService} from '@modules/modal';
import {EMPTY, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {UserNotificationModalComponent} from '../components/user-notification-modal/user-notification-modal.component';

@Injectable()
export class UserDownloadNotificationModalService {

  constructor(
    private readonly overlayService: OverlayService,
    private readonly overlay: Overlay,
  ) {
  }

  open(): Observable<number> {
    const config = new OverlayConfig({
      hasBackdrop: false,
      panelClass: ['user-download-notification'],
      disposeOnNavigation: false,
      positionStrategy: this.overlay.position().global().bottom().right(),
      scrollStrategy: this.overlay.scrollStrategies.reposition({scrollThrottle: 0}),
    });
    const ref = this.overlayService.open(UserNotificationModalComponent, null, config, {
      disableCloseBtn: true,
      animation: true,
    });
    if (!ref) {
      return EMPTY;
    }
    return ref.afterClosed$.pipe(map(e => e.data));
  }
}
