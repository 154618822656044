import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {closeIcon, SeazoneIconRegistryService, SeazoneIconsModule} from '@modules/seazone-icons';
import {TranslocoModule} from '@ngneat/transloco';

import {
  UserDownloadNotificationComponent,
} from './components/user-download-notification/user-download-notification.component';
import {UserNotificationModalComponent} from './components/user-notification-modal/user-notification-modal.component';
import {UserDownloadNotificationModalService} from './services/user-download-notification-modal.service';

@NgModule({
  declarations: [
    UserNotificationModalComponent,
    UserDownloadNotificationComponent,
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    RouterModule,
    SeazoneIconsModule,
  ],
  providers: [
    UserDownloadNotificationModalService,
  ],
  exports: [
    UserDownloadNotificationComponent,
  ],
})
export class UserDownloadNotificationModule {
  constructor(
    private readonly s: SeazoneIconRegistryService,
  ) {
    this.s.registerIcons([closeIcon]);
  }
}
