import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {CustomOverlayRef} from '@modules/modal';
import {EMPTY} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {switchMap, take, takeUntil} from 'rxjs/operators';

import {DestroySubscription} from '@helpers/destroy-subscription';
import {UserDocumentItemDto, UserDownloadFileStatus} from '@models/user';

import {USER_DOWNLOAD_NOTIFICATION_ANIMATION} from '../../animation/user-download-notification.animation';
import {
  UserDownloadNotificationService,
} from '../../services/user-download-notification/user-download-notification.service';

@Component({
  selector: 'app-user-notification-modal',
  templateUrl: './user-notification-modal.component.html',
  styleUrls: ['./user-notification-modal.component.scss'],
  animations: [USER_DOWNLOAD_NOTIFICATION_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNotificationModalComponent extends DestroySubscription implements OnInit {

  items$: Observable<UserDocumentItemDto[] | null>;
  UserDownloadFileStatus = UserDownloadFileStatus;

  constructor(
    private readonly userDownloadNotificationService: UserDownloadNotificationService,
    @Inject(CustomOverlayRef) private readonly overlayRef: CustomOverlayRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.items$ = this.userDownloadNotificationService.state$.pipe();
    this.onStateChanges();
  }

  trackById(index: number, item: UserDocumentItemDto): number {
    return item.id;
  }

  onClose(item: UserDocumentItemDto): void {
    this.setViewedStatus([item]);
  }

  onDownloadDocument(item: UserDocumentItemDto): void {
    this.userDownloadNotificationService.downloadDocument(item)
      .pipe(
        switchMap(res => {
          if (res && !item.downloaded) {
            return this.userDownloadNotificationService.setDownloadStatus([item]);
          }
          return EMPTY;
        }),
        takeUntil(this.destroyStream$),
      ).subscribe((val) => {
        if (val) {
          this.setViewedStatus([item]);
        }
      });
  }

  private setViewedStatus(items: UserDocumentItemDto[]): void {
    this.userDownloadNotificationService.setViewedStatus(items)
      .pipe(
        take(1),
        takeUntil(this.destroyStream$),
      ).subscribe();
  }

  private onStateChanges(): void {
    this.items$.pipe(
      takeUntil(this.destroyStream$),
    ).subscribe(items => {
      if (!items || items.length === 0) {
        this.overlayRef.close(null);
      }
    });
  }

}
