<ng-container *transloco="let t; read: 'userNotification'">
  <div *ngIf="items$ | async as items" class="user-download-notification__wrapper">
    <div *ngFor="let item of items; trackBy: trackById" @notificationAnimation class="user-download-notification__item">
      <div class="user-download-notification__header">
        <h4 class="user-download-notification__title">
          <ng-container [ngSwitch]="item?.status">
            <ng-container *ngSwitchCase="UserDownloadFileStatus.Ready">
              {{ t('successTitle') }}
            </ng-container>
            <ng-container *ngSwitchCase="UserDownloadFileStatus.Failed">
              {{ t('errorTitle') }}
            </ng-container>
          </ng-container>
        </h4>

        <button class="user-download-notification__close-btn" type="button" (click)="onClose(item)">
          <app-seazone-icon name="close" color="grey"></app-seazone-icon>
        </button>
      </div>

      <p class="user-download-notification__text">{{ item?.fileName }}</p>

      <div class="user-download-notification__footer">
        <button
          *ngIf="item?.status === UserDownloadFileStatus.Ready"
          type="button"
          (click)="onDownloadDocument(item)"
          class="user-download-notification__download-btn">
          {{ 'actions.download' | transloco }}
        </button>

        <a class="user-download-notification__link" [routerLink]="['./', 'downloads']">
          {{ t('goToDownloads') }}
        </a>
      </div>
    </div>
  </div>
</ng-container>
