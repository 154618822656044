import {Component, OnInit} from '@angular/core';
import {EMPTY} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';

import {PlatformBrowserService} from '@core/modules';
import {DestroySubscription} from '@helpers/destroy-subscription';

import {
  UserDownloadNotificationService,
} from '../../services/user-download-notification/user-download-notification.service';
import {UserDownloadNotificationModalService} from '../../services/user-download-notification-modal.service';

@Component({
  selector: 'app-user-download-notification',
  templateUrl: './user-download-notification.component.html',
  styleUrls: ['./user-download-notification.component.scss'],
})
export class UserDownloadNotificationComponent extends DestroySubscription implements OnInit {

  constructor(
    private readonly userDownloadNotificationService: UserDownloadNotificationService,
    private readonly userDownloadNotificationModalService: UserDownloadNotificationModalService,
    private readonly platformBrowserService: PlatformBrowserService,
  ) {
    super();
  }

  ngOnInit(): void {
    const isBrowser = this.platformBrowserService.isBrowser;
    if (isBrowser) {
      this.onNotificationStateChanges();
    }
  }

  private onNotificationStateChanges(): void {
    this.userDownloadNotificationService.state$
      .pipe(
        switchMap(items => {
          if (!items?.length) {
            return EMPTY;
          }
          return this.userDownloadNotificationModalService.open();
        }),
        takeUntil(this.destroyStream$),
      ).subscribe();
  }
}
