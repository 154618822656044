import {animate, style, transition, trigger} from '@angular/animations';

export const USER_DOWNLOAD_NOTIFICATION_ANIMATION = trigger('notificationAnimation', [
  transition(':enter', [
    style({opacity: 0, transform: 'translateY(100%)'}),
    animate('400ms ease-in', style({opacity: 1,  transform: 'none'})),
  ],
  ),
  transition(':leave', [
    style({opacity: 1, transform: 'translateY(0)'}),
    animate('400ms ease-out', style({opacity: 0, transform: 'translateY(100%)'})),
  ],
  ),
]);
